import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import { customMedia } from "../../styles/customMedia"
import { defaultTheme } from "../../styles/theme"
import GlobalStyles from "../../styles/global"
import {
  Footer,
  HeroContentSpreadsheet,
  SpreadsheetStatistics,
  SpreadsheetForm,
  SpreadsheetBenefits,
} from "../../components"
import SpeadsheetBackground from "../../../static/spreadsheet-page-background.png"

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;

  ${customMedia.greaterThan("mediumDesktop")`
    border-radius: 0;
  `}
`

export const BackgroundContainer = styled.div`
  background: linear-gradient(90deg, #151b1e 0%, #151b1e00 100%),
    url("${SpeadsheetBackground}");
`

function Planilha() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Helmet title="Planilha de Ativos - EQI" defer={false}/>
      <GlobalStyles />
      <MainContainer>
        <BackgroundContainer>
          <HeroContentSpreadsheet />
        </BackgroundContainer>
        <SpreadsheetStatistics />
        <SpreadsheetForm />
        <SpreadsheetBenefits />
        <Footer />
      </MainContainer>
    </ThemeProvider>
  )
}

export default Planilha
